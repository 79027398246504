export const get_users = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/get_users`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const post_user = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/set_users`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    console.log(result);
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const update_user = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/update_user`;
    const params = {
      method: 'PUT',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



export const delete_user = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_URL}/delete_user`;
    const params = {
      method: 'DELETE',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}