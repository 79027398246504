import {
  useMantineColorScheme,
  Group,
  Burger,
  Image,
  Box,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import Logo from "../assets/logo.png";
import { TbSun, TbMoon } from "react-icons/tb";

const Header = ({ opened, toggleSidebar }) => {
  const { setColorScheme, colorScheme } = useMantineColorScheme();

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Group h="100%" px="md" className="flex flex-row text-center">
        <Burger opened={opened} onClick={ () => { toggleSidebar(); } } hiddenFrom="sm" size="sm" />
        <div className={ `rounded-full bg-gray-100 border border-gray-800 w-12 mt-1` }>
              <Image className='w-full h-auto' src={ Logo } alt="logo" title="logo gc-com" />
            </div>
      </Group>
      {colorScheme === "dark" ? (
        <Tooltip label="Light mode">
          <ActionIcon
            radius="lg"
            color="gray"
            variant="transparent"
            className="mr-4"
            onClick={() => setColorScheme("light")}
          >
            <TbSun size={24} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Tooltip label="Dark mode">
          <ActionIcon
            radius="lg"
            color="black"
            variant="transparent"
            className="mr-4"
            onClick={() => setColorScheme("dark")}
          >
            <TbMoon size={24} />
          </ActionIcon>
        </Tooltip>
      )}
    </Box>
  );
};

export default Header;
