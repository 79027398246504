import { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import {postLogin} from '../../utils/apis/auth'

import {
    SUCCESSFUL_SIGNIN,
    ERROR_SIGNIN,
    GET_USER_INFO,
    SUCCESSFUL_LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    BLOCK
} from '../types';

const AuthState = (props) => {
    //STATE INICIAL
    const initialState = {
        username: localStorage.getItem('user'),
        message: null,
        token: localStorage.getItem('token'),
        autenticado: null
    };

    // Dispatch para ejecutar las acciones

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const sign_in = async (datos) => {
        // try {
        //     const res = await postSignIn(datos)
        
        //     if (res.msg === 'done') {
                
        //         dispatch({
        //             type: SUCCESSFUL_SIGNIN,
        //             payload: res
        //         })
        //         usuarioAutenticado(res.user)
        //     } else {
        //         const alerta = {
        //             msg: res.msg,
        //             categoria: 'danger'
        //         }

        //         dispatch({
        //             type: ERROR_SIGNIN,
        //             payload: alerta


        //         })
        //     }
        // } catch (errors) {
        //         console.log('ERROR', errors)
        //     const alerta = {
        //         msg: 'ERROR',
        //         categoria: 'alerta-error'
        //     }

        //     dispatch({
        //         type: ERROR_SIGNIN,
        //         payload: alerta


        //     })
        // }


    }

    const login = async (datos) => {
        try {
            const res = await postLogin(datos)
        
            if (res.security_token) {
                
                dispatch({
                    type: SUCCESSFUL_LOGIN,
                    payload: res
                })
                usuarioAutenticado(res.username)
            } else {
                const alerta = {
                    msg: res.msg,
                    categoria: 'danger'
                }

                dispatch({
                    type: ERROR_SIGNIN,
                    payload: alerta


                })
            }
        } catch (error) {

            const alerta = {
                msg: error,
                categoria: 'alerta-error'
            }

            dispatch({
                type: ERROR_SIGNIN,
                payload: alerta


            })
        }


    }

    // Obtener datos de usuario

    const usuarioAutenticado = async (userData) => {


        try {
            dispatch({
                type: GET_USER_INFO,
                payload: userData
            })

        } catch (error) {
            dispatch({
                type: ERROR_LOGIN
            })
        }
    }

    const block = () => {
        dispatch({
            type: BLOCK
        })
    }


    const logout = () => {
        dispatch({
            type: LOGOUT
        })
    }


    return (
        <AuthContext.Provider value={{
            username: state.username,
            token: state.token,
            autenticado: state.autenticado,
            message: state.message,
            sign_in,
            usuarioAutenticado,
            login,
            logout,
            block

        }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;