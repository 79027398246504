/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header';
import AuthContext from '../contexts/Auth/authContext';
import { AppShell, ScrollArea, NavLink } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { HiOutlineLogout } from 'react-icons/hi';

const PrivateRoute = ({ children }) =>
{
  const authContext = useContext(AuthContext);
  const { token, logout } = authContext;

  const [ opened, { toggle } ] = useDisclosure();

  return token ?
    <AppShell
      header={ { height: 60 } }
      navbar={ { width: 240, breakpoint: 'sm', collapsed: { mobile: !opened } } }
      padding="sm"
    >
      <AppShell.Header>
        <Header opened={ opened } toggleSidebar={ toggle } />
      </AppShell.Header>
      <AppShell.Navbar>
        <AppShell.Section grow my="md" component={ ScrollArea }>
          <Sidebar toggleSidebar={ toggle } />
        </AppShell.Section>

        <AppShell.Section>
          <NavLink onClick={ logout } label="Cerrar sesión" leftSection={ <HiOutlineLogout className='text-md lg:text-lg' /> } />
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>     { children }</AppShell.Main>
    </AppShell>

    : <Navigate to={ '/login' } replace />
}

export default PrivateRoute
