import React, { useContext } from 'react';
import { Box, Divider, Avatar, Text, NavLink } from "@mantine/core";
import {  useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/Auth/authContext';

import { HiOutlineDocumentReport, HiOutlineUsers, HiOutlineIdentification, HiOutlineReceiptTax } from "react-icons/hi";
import { BsInboxes } from 'react-icons/bs';
import { MdOutlineDashboard } from 'react-icons/md';

import MenuItem from './MenuItem';

const Sidebar = ({ toggleSidebar }) =>
{
  const { pathname } = useLocation();
  const { username } = useContext(AuthContext);

  const handleToggleSidebar = () =>
  {
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  return (
    <>
      <Box className='flex flex-row items-center gap-2 py-2 px-4'>
        <Avatar radius="xl" />
        { username && (
          <Text className='font-normal text-md mx-1'>{ username.replaceAll('"', '') }</Text>
        ) }
      </Box>

      <Divider className='my-2' />

      <Box className='flex flex-col items-stretch gap-2 px-4'>
        <MenuItem
          onClick={ handleToggleSidebar }
          icon={ <MdOutlineDashboard className='text-md lg:text-lg' /> }
          path='/dashboard'
          text='Dashboard'
          active={ pathname === '/dashboard' }
        />

        <NavLink
          label="Vencimiento"
          leftSection={ <BsInboxes className='text-md lg:text-lg' /> }
          childrenOffset={ 28 }
        >
          <MenuItem
            onClick={ handleToggleSidebar }
            path='/renovation/ten-days'
            active={ pathname === '/renovation/ten-days' }
            text='10 días'
          />
          <MenuItem
            onClick={ handleToggleSidebar }
            path='/renovation/thirty-days'
            active={ pathname === '/renovation/thirty-days' }
            text='30 días'
          />
        </NavLink>

        <MenuItem
          onClick={ handleToggleSidebar }
          icon={ <HiOutlineDocumentReport className='text-md lg:text-lg' /> }
          path='/plans'
          text='Planes'
          active={ pathname === '/plans' }
        />

        <MenuItem
          onClick={ handleToggleSidebar }
          icon={ <HiOutlineReceiptTax className='text-md lg:text-lg' /> }
          path='/reports'
          text='Transacciones'
          active={ pathname === '/reports' }
        />

        <MenuItem
          onClick={ handleToggleSidebar }
          icon={ <HiOutlineIdentification className='text-md lg:text-lg' /> }
          path='/customers'
          text='Clientes'
          active={ pathname === '/customers' }
        />

        <MenuItem
          onClick={ handleToggleSidebar }
          icon={ <HiOutlineUsers className='text-md lg:text-lg' /> }
          path='/users'
          text='Usuarios'
          active={ pathname === '/users' }
        />
      </Box>
    </>
  );
};

export default Sidebar;
